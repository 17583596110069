<template>
  <div class="quotes p-8">
    <div class="mx-auto max-w-5xl space-y-6">
      <div class="flex justify-start items-center gap-1">
        <svg
          @click="router.go(-1)"
          class="h-8 w-8 text-blue-600 hover:text-blue-700 cursor-pointer"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"
          />
        </svg>
        <h1 class="text-3xl font-semibold ">
          Edit Quote {{ quote.sequence_number }}
        </h1>
      </div>

      <div v-if="isLoading" class="flex justify-center gap-1">
        <div class="spinner dark"></div>
        <div>Loading</div>
      </div>

      <form @submit.prevent="updateQuote" class="max-w-3xl space-y-5">
        <div class="text-lg space-y-5">
          <div class="w-full flex">
            <div class="w-full">
              <label
                class="text-sm font-medium text-gray-600 flex gap-2 align-middle"
              >
                Customer
                <svg
                  v-if="
                    (user.claims.user_role == 'superadmin' ||
                      user.claims.user_role == 'masquerade' ||
                      user.claims.user_role == 'admin' ||
                      user.claims.user_role == 'office') &&
                      !quote.locked
                  "
                  @click="updateCustomer"
                  xmlns="http://www.w3.org/2000/svg"
                  :class="{ updating: isUpdatingCustomer }"
                  class="mt-0.5 h-4 w-4 hover:text-blue-500 cursor-pointer"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                  />
                </svg>
              </label>
              <div>{{ quote.customer?.billing_business }}</div>
              <div>{{ quote.customer?.customer_name }}</div>
              <div>{{ quote.customer?.customer_phone }}</div>
              <div>{{ quote.customer?.customer_email }}</div>
              <label
                v-if="
                  quote.customer?.billing_phone ||
                    quote.customer?.billing_address1 ||
                    quote.customer?.billing_address2 ||
                    quote.customer?.billing_city ||
                    quote.customer?.billing_state ||
                    quote.customer?.billing_zip
                "
                class="mt-3 block text-sm font-medium text-gray-600"
              >
                Billing
              </label>
              <div>{{ quote.customer?.billing_phone }}</div>
              <div>{{ quote.customer?.billing_address1 }}</div>
              <div>{{ quote.customer?.billing_address2 }}</div>
              <div>
                {{ quote.customer?.billing_city }}
                {{ quote.customer?.billing_state }}
                {{ quote.customer?.billing_zip }}
              </div>
            </div>
            <div class="w-full">
              <label
                class="text-sm font-medium text-gray-600 flex gap-2 align-middle"
              >
                Location
                <svg
                  v-if="
                    (user.claims.user_role == 'superadmin' ||
                      user.claims.user_role == 'masquerade' ||
                      user.claims.user_role == 'admin' ||
                      user.claims.user_role == 'office') &&
                      !quote.locked
                  "
                  @click="updateLocation"
                  xmlns="http://www.w3.org/2000/svg"
                  :class="{ updating: isUpdatingLocation }"
                  class="mt-0.5 h-4 w-4 hover:text-blue-500 cursor-pointer"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                  />
                </svg>
              </label>
              <div>{{ quote.location?.location_address1 }}</div>
              <div>{{ quote.location?.location_address2 }}</div>
              <div>
                {{ quote.location?.location_city }}
                {{ quote.location?.location_state }}
                {{ quote.location?.location_zip }}
              </div>
            </div>
          </div>

          <div>
            <label
              for="assigned_to"
              class="block text-sm font-medium text-gray-600"
            >
              Assigned
            </label>
            <div class="mt-1">
              <select
                @change="setStatusWhenAssigned()"
                v-model="quote.assigned_to"
                :disabled="user.claims.user_role == 'estimator' || quote.locked"
                name="assigned_to"
                class="w-full border rounded px-4 py-2 border-gray-200 text-lg"
              >
                <option> </option>
                <option
                  v-for="username in users"
                  :key="username.id"
                  :value="username.id"
                  >{{ username.user_name }}</option
                >
              </select>
            </div>
          </div>

          <div class="flex text-gray-400">
            <label class="w-full" for="pending">
              <input
                v-model="quote.quote_status"
                :disabled="user.claims.user_role == 'estimator' || quote.locked"
                type="radio"
                class="sr-only"
                id="pending"
                value="pending"
              />
              <div
                :class="{
                  'bg-blue-500 text-white font-semibold border-l-8 border-blue-400':
                    quote.quote_status == 'pending',
                }"
                class="w-full py-2 flex justify-center align-middle rounded-l border-l-1 border-t border-b border-gray-300"
              >
                Pending
              </div>
            </label>
            <label class="w-full" for="assigned">
              <input
                v-model="quote.quote_status"
                :disabled="quote.locked"
                type="radio"
                class="sr-only"
                id="assigned"
                value="assigned"
              />
              <div
                :class="{
                  'bg-blue-500 text-white font-semibold border-l-8 border-blue-400':
                    quote.quote_status == 'assigned',
                }"
                class="w-full py-2 flex justify-center align-middle border-t border-b border-gray-300"
              >
                Assigned
              </div>
            </label>
            <label class="w-full" for="processed">
              <input
                v-model="quote.quote_status"
                :disabled="quote.locked"
                type="radio"
                class="sr-only"
                id="processed"
                value="processed"
              />
              <div
                :class="{
                  'bg-blue-500 text-white font-semibold border-l-8 border-blue-400':
                    quote.quote_status == 'processed',
                }"
                class="w-full py-2 flex justify-center align-middle border-t border-b border-gray-300"
              >
                Processed
              </div>
            </label>
            <label class="w-full" for="reviewed">
              <input
                v-model="quote.quote_status"
                :disabled="user.claims.user_role == 'estimator' || quote.locked"
                type="radio"
                class="sr-only"
                id="reviewed"
                value="reviewed"
              />
              <div
                :class="{
                  'bg-blue-500 text-white font-semibold border-l-8 border-blue-400':
                    quote.quote_status == 'reviewed',
                }"
                class="w-full py-2 flex justify-center align-middle border-t border-b border-gray-300"
              >
                Reviewed
              </div>
            </label>
            <label class="w-full" for="emailed">
              <input
                v-model="quote.quote_status"
                :disabled="user.claims.user_role == 'estimator' || quote.locked"
                type="radio"
                class="sr-only"
                id="emailed"
                value="emailed"
              />
              <div
                :class="{
                  'bg-blue-500 text-white font-semibold border-l-8 border-blue-400':
                    quote.quote_status == 'emailed',
                }"
                class="w-full py-2 flex justify-center align-middle rounded-r border-r border-t border-b border-gray-300"
              >
                Emailed
              </div>
            </label>
          </div>

          <div>
            <label
              for="quote_notes"
              class="block text-sm font-medium text-gray-600"
            >
              Quote Notes (Internal Use)
            </label>
            <textarea
              v-model="quote.quote_notes"
              name="quote_notes"
              :disabled="quote.locked"
              class="mt-1 w-full rounded border border-gray-200 px-4 py-2"
              rows="4"
            ></textarea>
          </div>

          <div class="flex items-center">
            <button
              type="button"
              @click="quote.accepted = !quote.accepted"
              :class="{ 'bg-blue-500': quote.accepted }"
              :disabled="quote.locked"
              class="bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none"
            >
              <span
                :class="{ 'translate-x-5': quote.accepted }"
                class="translate-x-0 pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
              ></span>
            </button>
            <span class="ml-3">
              <span class="text-sm font-medium text-gray-900">Accepted</span>
            </span>
          </div>

          <div>
            <label class="block text-sm font-medium text-gray-600">
              Attachments
            </label>
            <input
              @change="selectImages"
              :disabled="quote.locked"
              id="quote_attachment_picker"
              accept="image/jpeg,image/png"
              multiple
              type="file"
              class="mt-1 px-4 py-2 w-full border rounded border-gray-200 text-sm"
            />
          </div>

          <div class="flex flex-wrap gap-3">
            <div
              v-for="(image, index) in quote.attachments"
              :key="image"
              class="relative w-36 h-36 object-cover"
            >
              <svg
                @click="removeImage(index)"
                v-if="!quote.locked"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                class="w-7 h-7 text-black bg-white rounded-full cursor-pointer absolute -top-2 -right-2"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clip-rule="evenodd"
                />
              </svg>
              <img
                @click="modalImage(`${image.preview ?? image.url}`)"
                :src="image.preview ?? image.url"
                class="w-full h-full object-cover"
              />
            </div>
          </div>

          <div
            class="mb-8 px-4 pb-4 border rounded space-y-5 relative"
            v-for="(service_line, service_index) in service_lines"
            :key="service_line && service_line.id"
          >
            <svg
              @click="removeServiceLine(service_index)"
              v-if="!quote.locked"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              class="w-7 h-7 text-black bg-white rounded-full cursor-pointer absolute -top-3 -right-3"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                clip-rule="evenodd"
              />
            </svg>
            <div>
              <label
                for="service_name"
                :class="{ 'mt-2': quote.locked }"
                class="block text-sm font-medium text-gray-600"
              >
                Service Name
              </label>
              <input
                v-model="service_line.service_name"
                :disabled="quote.locked"
                maxlength="50"
                name="service_name"
                class="mt-1 w-full rounded px-4 py-2"
              />
            </div>

            <div>
              <label
                for="service_description"
                class="block text-sm font-medium text-gray-600"
              >
                Service Description
              </label>
              <textarea
                v-model="service_line.service_description"
                name="service_description"
                :disabled="quote.locked"
                class="mt-1 w-full rounded border-gray-200 px-4 py-2"
                rows="3"
              ></textarea>
            </div>

            <div class="w-full flex gap-2">
              <div class="flex flex-col">
                <label
                  for="service_unit_name"
                  class="block text-sm font-medium text-gray-600"
                >
                  Quantity
                </label>
                <input
                  :disabled="quote.locked"
                  @change="
                    updateSubtotals(
                      service_line.quantity,
                      service_line.override_price,
                      service_index
                    )
                  "
                  type="number"
                  class="w-full px-4 py-2 rounded-l border-gray-200"
                  v-model.number="service_line.quantity"
                />
              </div>
              <div class="flex flex-col">
                <label
                  for="service_unit_name"
                  class="block text-sm font-medium text-gray-600"
                >
                  Unit
                </label>
                <input
                  type="text"
                  disabled
                  maxlength="10"
                  class="w-full px-4 py-2 border-gray-200"
                  name="service_unit_name"
                  v-model="service_line.service_unit_name"
                />
              </div>
              <div class="flex flex-col justify-end items-start relative">
                <label
                  for="service_unit_name"
                  class="block text-sm font-medium text-gray-600"
                >
                  Price
                </label>

                <input
                  :disabled="quote.locked"
                  @change="
                    updateSubtotals(
                      service_line.quantity,
                      service_line.override_price,
                      service_index
                    )
                  "
                  type="text"
                  class="w-full px-4 py-2 border-gray-200"
                  v-model="service_line.override_price"
                />
                <div
                  v-if="
                    service_line.override_price !== service_line.service_price
                  "
                  class="mb-0.5 absolute right-0 select-none line-through px-4 py-2 text-base"
                >
                  {{ service_line.service_price }}
                </div>
              </div>
              <div class="flex flex-col">
                <label
                  for="service_unit_name"
                  class="block text-sm font-medium text-gray-600"
                >
                  Extended Price
                </label>
                <input
                  type="text"
                  :disabled="quote.locked"
                  class="w-full px-4 py-2 rounded-r border-gray-200"
                  v-model.number="service_line.extended_price"
                />
              </div>
            </div>

            <div>
              <label class="block text-sm font-medium text-gray-600">
                Service Attachments
              </label>
              <input
                @change="selectImagesService($event, service_index)"
                :disabled="quote.locked"
                :id="'service_attachment_picker-' + service_index"
                accept="image/jpeg,image/png"
                multiple
                type="file"
                class="mt-1 px-1 py-2 w-full text-sm"
              />
            </div>

            <div class="flex flex-wrap gap-3  ">
              <div
                v-for="(image, image_index) in service_line.images"
                :key="image"
                class="relative w-34 h-34 object-cover"
              >
                <svg
                  @click="removeImageService(service_index, image_index)"
                  v-if="!quote.locked"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="w-7 h-7 text-black bg-white rounded-full cursor-pointer absolute -top-2 -right-2"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clip-rule="evenodd"
                  />
                </svg>
                <img
                  @click="modalImage(`${image.preview ?? image.url}`)"
                  :src="image.preview ?? image.url"
                  class="w-full h-full object-cover"
                />
              </div>
            </div>
          </div>

          <div>
            <label
              for="services"
              class="block text-sm font-medium text-gray-600"
            >
              Services
            </label>
            <div class="flex gap-2" v-if="!quote.locked">
              <select
                v-model="quote.services"
                name="services"
                class="w-full border rounded px-4 py-2 border-gray-200 text-lg"
              >
                <option> </option>
                <option
                  v-for="service in services"
                  :key="service.id"
                  :value="service.id"
                  >{{ service.service_name }}</option
                >
              </select>
              <div class="flex justify-center">
                <button
                  type="button"
                  @click="selectServiceLine()"
                  class="bg-blue-600 submit-btn text-white text-2xl font-bold w-60 rounded py-2 flex justify-center gap-2"
                >
                  <div v-if="isPendingAddService" class="spinner"></div>
                  Add Service
                </button>
              </div>
            </div>
          </div>

          <div>
            <label
              for="total_price"
              class="block text-sm font-medium text-gray-600"
            >
              Calculated Price
            </label>
            <input
              v-model="quote.total_price"
              disabled
              name="total_price"
              :class="{ 'line-through': quote.override_price }"
              class="mt-1 w-full border rounded px-4 py-2 border-gray-200 text-lg"
            />
          </div>

          <div>
            <label
              for="override_price"
              class="block text-sm font-medium text-gray-600"
            >
              Override Price
            </label>
            <input
              @change="
                quote.override_price = formatCurrency(quote.override_price)
              "
              :disabled="quote.locked"
              v-model="quote.override_price"
              name="override_price"
              class="mt-1 w-full border rounded px-4 py-2 border-gray-200 text-lg"
            />
          </div>

          <div class="pt-2 flex justify-center" v-if="!quote.locked">
            <button
              type="submit"
              class="bg-blue-600 submit-btn text-white text-2xl font-bold w-60 rounded py-2 flex justify-center gap-2"
            >
              <div v-if="isPending" class="spinner"></div>
              Save
            </button>
          </div>

          <div class="text-center text-red-600 font-semibold">
            {{ error }}
            {{ users_err }}
            {{ service_lines_err }}
            {{ services_err }}
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router";
import { db, timestamp, storage } from "@/firebaseConfig";
import getCollection from "@/composables/getCollection";
import getDocument from "@/composables/getDocument";
import useDocument from "@/composables/useDocument";
import useCollection from "@/composables/useCollection";

export default {
  props: ["quote_id", "user"],
  emits: ["loadModalImage"],
  setup(props, context) {
    const isLoading = ref(true);
    const isUpdatingCustomer = ref(false);
    const isUpdatingLocation = ref(false);
    const route = useRoute();
    const router = useRouter();
    const isPending = ref(false);
    const isPendingAddService = ref(false);
    const removedServiceLines = ref([]);
    const images = ref([]);
    let customer = {};
    let location = {};

    const business_id = props.user.claims.business_id ?? props.id;

    // read functions
    const { error, document: quote } = getDocument(
      `businesses/${business_id}/quotes`,
      props.quote_id
    );

    const { error: users_err, documents: users } = getCollection(
      `businesses/${business_id}/users`
    );

    const {
      error: service_lines_err,
      documents: service_lines,
    } = getCollection(
      `businesses/${business_id}/quotes/${props.quote_id}/service_lines`
    );

    const { error: services_err, documents: services } = getCollection(
      `businesses/${business_id}/services`
    );

    const assigned_to = computed(() => {
      if (users.value) {
        return users.value
          .filter((u) => u.id == quote.value.assigned_to)
          .shift();
      }
      return null;
    });

    watchEffect(async () => {
      images.value = quote.value.attachments && [...quote.value.attachments];

      if (quote.value && users.value && service_lines.value && services.value) {
        isLoading.value = false;
      }
    });

    // write functions
    const { error: write_err, updateDoc: quote_updateDoc } = useDocument(
      `businesses/${business_id}/quotes`,
      props.quote_id
    );

    const { job_error, addDoc: job_addDoc } = useCollection(
      `businesses/${business_id}/jobs`
    );

    // attachment functions
    const selectImages = (event) => {
      const files = event.target.files;
      images.value = quote.value.attachments
        ? [...quote.value.attachments]
        : [];

      files.forEach((imageData) => {
        images.value.push({
          image: imageData,
          preview: URL.createObjectURL(imageData),
          name: new Date().getTime() + "_" + imageData.name,
        });
      });

      const picker = document.getElementById("quote_attachment_picker");
      picker.value = "";
      quote.value.attachments = [...images.value];
    };

    const removeImage = (index) => {
      quote.value.attachments.splice(index, 1);
    };

    const selectImagesService = (event, index) => {
      const files = event.target.files;
      const array = service_lines.value[index].images ?? [];
      const picker = document.getElementById(
        `service_attachment_picker-${index}`
      );

      files.forEach((file) => {
        array.push({
          image: file,
          preview: URL.createObjectURL(file),
          name: new Date().getTime() + "_" + file.name,
        });
      });

      service_lines.value[index].images = array;
      picker.value = "";
    };

    const removeImageService = (service_index, image_index) => {
      service_lines.value[service_index].images.splice(image_index, 1);
    };

    // button submit functions
    const selectServiceLine = () => {
      isPendingAddService.value = true;
      if (quote.value.services) {
        const selected_service = services.value
          .filter((service) => service.id == quote.value.services)
          .shift();
        const lines = service_lines.value.push({
          quantity: 1,
          id: null,
          service_id: selected_service.id,
          service_description: selected_service.service_description,
          service_name: selected_service.service_name,
          service_price: selected_service.service_price,
          service_status: selected_service.service_status,
          service_unit_name: selected_service.service_unit_name,
          override_price: selected_service.service_price,
        });
        updateSubtotals(1, selected_service.service_price, lines - 1);
      }
      isPendingAddService.value = false;
    };

    const removeServiceLine = (service_index) => {
      updateSubtotals(0, 0, service_index);
      removedServiceLines.value.push(service_lines.value[service_index].id);
      service_lines.value.splice(service_index, 1);
    };

    const updateQuote = async () => {
      isPending.value = true;
      let downloadable_images = [];
      let new_downloadable_images = [];
      let currentImages = [];

      if (images.value && images.value.length != 0) {
        currentImages = images.value.filter((c) => !c.image);
        const newImages = images.value.filter((c) => c.image);

        const quote_images = newImages.map(async (image) => {
          const storageRef = storage.ref(
            `${business_id}/quotes/${props.quote_id}/${image.name}`
          );
          await storageRef.put(image.image);

          const url = await storage
            .ref(business_id)
            .child("quotes")
            .child(props.quote_id)
            .child(image.name)
            .getDownloadURL();

          return { url: url, filename: image.name };
        });

        new_downloadable_images = await Promise.all(quote_images);
      }

      downloadable_images = [...new_downloadable_images, ...currentImages];

      if (quote.value.accepted && !quote.value.locked) {
        await job_addDoc({
          createdAt: timestamp(),
          createdBy: props.user.uid,
          sequence_number: quote.value.sequence_number,
          job_notes: quote.value.quote_notes,
          customer: quote.value.customer,
          location: quote.value.location,
          attachments: quote.value.attachments,
          service_lines: service_lines.value,
        });
        quote.value.locked = true;
      }

      await quote_updateDoc({
        assigned_to: quote.value.assigned_to ?? "",
        quote_notes: quote.value.quote_notes ?? "",
        quote_status: quote.value.quote_status,
        accepted: quote.value.accepted,
        locked: quote.value.locked,
        total_price: quote.value.total_price,
        override_price: quote.value.override_price ?? "",
        attachments: downloadable_images,
      });

      service_lines.value.forEach(async (service_line) => {
        if (service_line && service_line.id) {
          let imagesURL = [];
          if (service_line?.images) {
            const serviceRawNewImages = service_line.images.filter(
              (c) => c.image
            );
            const serviceCurrentImages = service_line.images.filter(
              (c) => !c.image
            );
            const images_snapshot = serviceRawNewImages.map(async (image) => {
              const storageRef = storage.ref(
                `${business_id}/quotes/${props.quote_id}/services/${service_line.service_id}/${image.name}`
              );
              await storageRef.put(image.image);

              const url = await storage
                .ref(business_id)
                .child("quotes")
                .child(props.quote_id)
                .child("services")
                .child(service_line.service_id)
                .child(image.name)
                .getDownloadURL();

              return { url: url, filename: image.name };
            });

            const serviceDowloadableNewImages = await Promise.all(
              images_snapshot
            );

            imagesURL = [
              ...serviceDowloadableNewImages,
              ...serviceCurrentImages,
            ];
          }
          await db
            .doc(
              `businesses/${business_id}/quotes/${props.quote_id}/service_lines/${service_line.id}`
            )
            .update({
              service_name: service_line.service_name,
              service_description: service_line.service_description,
              service_price: service_line.service_price,
              service_unit_name: service_line.service_unit_name,
              quantity: service_line.quantity,
              extended_price: service_line.extended_price,
              override_price: service_line.override_price,
              images: imagesURL,
            });
        } else {
          const service_line_snapshot = await db
            .collection(
              `businesses/${business_id}/quotes/${props.quote_id}/service_lines`
            )
            .add({
              createdAt: timestamp(),
              createdBy: props.user.uid,
              service_id: service_line.service_id,
              service_name: service_line.service_name,
              service_description: service_line.service_description,
              service_price: service_line.service_price,
              service_unit_name: service_line.service_unit_name,
              quantity: service_line.quantity,
              extended_price: service_line.extended_price,
              override_price: service_line.override_price,
            });

          const service_line_id = service_line_snapshot.id;

          const imagesSnapshot =
            service_line &&
            service_line.images &&
            service_line.images.length != 0 &&
            service_line.images.map(async (image) => {
              const storageRef = storage.ref(
                `${business_id}/quotes/${props.quote_id}/services/${service_line.service_id}/${image.name}`
              );

              await storageRef.put(image.image);
              const url = await storage
                .ref(business_id)
                .child("quotes")
                .child(props.quote_id)
                .child("services")
                .child(service_line.service_id)
                .child(image.name)
                .getDownloadURL();

              return { url: url, filename: image.name };
            });

          if (imagesSnapshot) {
            const imagesURL = await Promise.all(imagesSnapshot);

            await db
              .doc(
                `businesses/${business_id}/quotes/${props.quote_id}/service_lines/${service_line_id}`
              )
              .update({
                images: imagesURL,
              });
          }
        }
      });

      if (removedServiceLines.value.length != 0) {
        removedServiceLines.value.forEach(async (serviceline_id) => {
          await db
            .doc(
              `businesses/${business_id}/quotes/${props.quote_id}/service_lines/${serviceline_id}`
            )
            .delete();
        });
      }
      isPending.value = false;
      if (!write_err.value) {
        router.push(`/quotes/${props.quote_id}`);
      }
    };

    // utility functions
    const updateSubtotals = (qty, price, index) => {
      // proper rounding
      price = Math.round((Number(price) + Number.EPSILON) * 100) / 100 || 0;

      // update line price format
      service_lines.value[index].override_price = formatCurrency(price);

      // update line total
      service_lines.value[index].extended_price = Number(qty * price).toFixed(
        2
      );

      // update quote total
      quote.value.total_price = formatCurrency(
        service_lines.value
          .map((s) => s.extended_price)
          .reduce((prev, next) => Number(prev) + Number(next))
      );
    };

    const setStatusWhenAssigned = () => {
      if (
        quote.value.assigned_to != "" &&
        quote.value.quote_status == "pending"
      ) {
        quote.value.quote_status = "assigned";
      }
      if (quote.value.assigned_to == "") {
        quote.value.quote_status = "pending";
      }
    };

    watch(quote, async (after, before) => {
      // loads values for the customer and location
      // after the quote collection has been loaded
      if (before.customer_id != after.customer_id) {
        customer = await getDocument(
          `businesses/${business_id}/customers`,
          quote.value.customer_id
        ).document;
      }
      if (before.location_id != after.location_id) {
        location = await getDocument(
          `businesses/${business_id}/locations`,
          quote.value.location_id
        ).document;
      }
    });

    const updateCustomer = () => {
      isUpdatingCustomer.value = true;
      quote_updateDoc({
        customer: customer.value,
      });
      setTimeout(() => (isUpdatingCustomer.value = false), 1000);
    };

    const updateLocation = () => {
      isUpdatingLocation.value = true;
      quote_updateDoc({
        location: location.value,
      });
      setTimeout(() => (isUpdatingLocation.value = false), 1000);
    };

    const modalImage = (data) => {
      context.emit("loadModalImage", data);
    };

    return {
      isLoading,
      route,
      router,
      isPending,
      isPendingAddService,
      images,
      error,
      quote,
      users_err,
      users,
      job_error,
      service_lines_err,
      service_lines,
      services_err,
      services,
      assigned_to,
      selectImages,
      removeImage,
      selectImagesService,
      removeImageService,
      selectServiceLine,
      removeServiceLine,
      write_err,
      updateQuote,
      updateSubtotals,
      setStatusWhenAssigned,
      formatCurrency,
      updateCustomer,
      updateLocation,
      isUpdatingCustomer,
      isUpdatingLocation,
      modalImage,
    };
  },
};

function formatCurrency(val) {
  val = val.toString().replace(/[^0-9.]/g, "");
  if (val.trim() == "") {
    return null;
  }
  return Number(val).toFixed(2);
}
</script>

<style scoped>
.updating {
  animation: spin-around 1s infinite linear;
}
</style>
